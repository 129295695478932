import moment from 'moment';
import StringUtil from './core/StringUtil';

// Not sure on a name, just rolling around here
enum Cookie {
  Auth_Cookie = 'auth_cookie',
  Auth_State = 'auth_state',
  Auth_Expiration = 'auth_expiration',
  Selected_Account = 'selected_account',
  Selected_Location = 'selected_location',
}

/**
 * Provides quick and easy access to LocalStorage. Helps by keeping keys in one file and by aiding in serializing and deserializing data
 */
class LocalStorageUtil {
  /** Auth Cookie */
  public get AuthCookie() : string | null {
    return this.getItem_Internal(Cookie.Auth_Cookie);
  }
  public set AuthCookie(value : string | null) {
    this.setItem_Internal(Cookie.Auth_Cookie, value);
  }

  /** Auth State */
  public get AuthState() : string | null {
    return this.getItem_Internal(Cookie.Auth_State);
  }
  public set AuthState(value : string | null) {
    this.setItem_Internal(Cookie.Auth_State, value);
  }

  /** Auth Expiration */
  public get AuthExpiration() : moment.Moment | null {
    const value = this.getItem_Internal(Cookie.Auth_Expiration);
    return StringUtil.IsNullOrEmpty(value) ? null : moment(value);
  }
  public set AuthExpiration(value : moment.Moment | null) {
    this.setItem_Internal(Cookie.Auth_Expiration, value == null ? null : value.format());
  }

  /** Selected Account */
  public get SelectedAccount() : string | null {
    return this.getItem_Internal(Cookie.Selected_Account);
  }
  public set SelectedAccount(value : string | null) {
    this.setItem_Internal(Cookie.Selected_Account, value);
  }

  /** Selected Location */
  public get SelectedLocation() : string | null {
    return this.getItem_Internal(Cookie.Selected_Location);
  }
  public set SelectedLocation(value : string | null) {
    this.setItem_Internal(Cookie.Selected_Location, value);
  }

  /** Internals */
  protected getItem_Internal(token: string) {
    return localStorage.getItem(token);
  }

  protected setItem_Internal(token: string, value: string | null) {
    if (value == null) {
      localStorage.removeItem(token);
    } else {
      localStorage.setItem(token, value);
    }
  }
}

export default new LocalStorageUtil();
