import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker, Form, Input, Rate, Select, Space } from 'antd';
import { useStateAsync } from 'src/hooks';

export interface LanguageProficiencyValue {
  /** This is a the language code, such as 'en-us' */
  languageCode?: string;
  proficiencyId?: number;
}

export interface LanguageProficiencySelectorProps {
  disabled?: boolean;
  hideProficiencyLabel?: boolean;

  // Props pulled from RangePicker
  // Dev Note: This is just SO hard to implement, so we are not
  // defaultValue?: LanguageProficiencyValue;
  value?: LanguageProficiencyValue;
  onChange?: (value: LanguageProficiencyValue) => void;
}

const LanguageProficiencySelector: React.FC<LanguageProficiencySelectorProps> = (props) => {
  const languages: { label: string, value: string }[] = [
    { value: 'en', label: 'English' },
    { value: 'uk', label: 'Ukrainian' },
    { value: 'ru', label: 'Russian' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'es', label: 'Spanish' },
    { value: 'it', label: 'Italian' },
    { value: 'ja', label: 'Japanese' },
    { value: 'ko', label: 'Korean' },
  ];

  // const proficiencies = ['Basic', 'Simple', 'Independent', 'Complex', 'Proficient', 'Fluent'];
  // Dev Note: Label is what can be shown on the label. The value is actually the order of the proficiency in the DB, hopefully. I am sure that hardcoding this in is not a bad thing rofl
  const proficiencies: { id: number, label: string, value: number }[] = [
    { id: 1, value: 0, label: 'None' },
    { id: 2, value: 1, label: 'Basic' },
    { id: 3, value: 2, label: 'Simple' },
    { id: 4, value: 3, label: 'Independent' },
    { id: 5, value: 4, label: 'Complex' },
    { id: 6, value: 5, label: 'Proficient' },
    { id: 7, value: 6, label: 'Fluent' },
  ];

  const [languageCode, setLanguageCode] = useState<string | undefined>();
  const [proficiencyValue, setProficiencyValue] = useState<number | undefined>();

  useEffect(() => {
    if (props.value == null) {
      return;
    }
    setLanguageCode(props.value.languageCode);
    setProficiencyValue(proficiencies.find(x => x.id === props.value!.proficiencyId)?.value);
  }, [props.value]);

  const handleLanguageChange = (languageCode: string) => {
    setLanguageCode(languageCode);
    props.onChange?.({ languageCode, proficiencyId: proficiencies.find(x => x.value === proficiencyValue)?.id });
  };

  const handleProficiencyChange = (proficiencyValue: number) => {
    // The proficiency value will be 0-6, we need to pass this through the proficiencies collection to get the ID, which is passed up
    setProficiencyValue(proficiencyValue);
    props.onChange?.({ languageCode, proficiencyId: proficiencies.find(x => x.value === proficiencyValue)?.id });
  };

  return (<Space>
    <Select
      style={{ width: 150 }}
      placeholder="Select Language"
      disabled={props.disabled}
      options={languages}
      value={languageCode}
      onChange={handleLanguageChange}
    />
    <Rate
      allowClear={false}
      count={6}
      value={proficiencyValue}
      onChange={handleProficiencyChange}
    />
    {!props.hideProficiencyLabel && <span className="ant-rate-text">{(proficiencyValue && proficiencies.find(x => x.value === proficiencyValue)?.label) ?? 'None'}</span>}
  </Space>);
};

export default LanguageProficiencySelector;
