import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, Space, Switch, Skeleton, Button, Form, Input } from 'antd';
import { NameOf, NotificationUtil, ObjectUtil, TableExpressions } from 'src/utils';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { useStateAsync } from 'src/hooks/UseStateAsync';
import AuthorizationController from 'src/api/AuthorizationController';
import './HomePage.less';
import { Link } from 'react-router-dom';
import RouteConfig from 'src/config/RouteConfig';

interface LoginPasswordFormData {
  email: string;
  password: string;
}

const HomePage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const [form] = Form.useForm<LoginPasswordFormData>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // loadTableData();
  }, []);

  const renderWelcome = () => {
    if (authContext.isLoggedIn) {
      return <p>{authContext.profile?.firstName} {authContext.profile?.lastName}</p>;
    }
    return null;
  };

  return (
    <div className='home-page'>
      <h1>Home</h1>
      {renderWelcome()}
      <span>Profile: </span>
      <Link to={RouteConfig.PROFILE()}>
        <Button type='primary' danger>Profile</Button>
      </Link>
    </div>
  );
};

export default HomePage;
