import { Button, Card, Col, Form, Image, Layout, Menu, Row, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import RouteConfig from 'src/config/RouteConfig';
// import lowerLogo from 'src/assets/company-logo-with-name.svg';
import headerImage from 'src/assets/people-holding-chat-bubbles.jpg';
import joinTeamImage from 'src/assets/people-giving-thumbs-up.jpg';
import { NameOf, StringUtil } from 'src/utils';
import StandardFooter from './StandardFooter';
import UserController from 'src/api/UserController';
import InterpreterDisplayResponse from 'src/models/generated/InterpreterDisplayResponse';
import imageNotFound from 'src/assets/core/image-not-found.png';
import './HomeLayout.less';

const { Content, Header, Footer } = Layout;

interface QuickSearchFormData {
  country: string;
  city: string;
  service: string;
  language: string;
}

const HomeLayout: React.FC<React.PropsWithChildren> = (props) => {
  const [form] = Form.useForm<QuickSearchFormData>();
  const [interpreters, setInterpreters] = useState<InterpreterDisplayResponse[]>([]);
  const [loading, setLoading] = useState(false);

  const countries: { label: string, value: string }[] = [
    { value: '1', label: 'Ukraine' }
  ];
  const cities: { label: string, value: string }[] = [
    { value: '1', label: 'Kyiv' },
    { value: '2', label: 'Kharkiv' },
    { value: '3', label: 'Odesa' },
    { value: '4', label: 'Dnipro' },
    { value: '5', label: 'Donetsk' },
    { value: '6', label: 'Zaporizhzhia' },
    { value: '7', label: 'Lviv' },
    { value: '8', label: 'Kryvyi Rih' },
    { value: '9', label: 'Mykolaiv' },
    { value: '10', label: 'Mariupol' },
    { value: '11', label: 'Luhansk' },
    { value: '12', label: 'Sevastopol' },
    { value: '13', label: 'Vinnytsia' },
    { value: '14', label: 'Makiivka' },
    { value: '15', label: 'Simferopol' },
    { value: '16', label: 'Kherson' },
    { value: '17', label: 'Poltava' },
    { value: '18', label: 'Chernihiv' },
    { value: '19', label: 'Cherkasy' },
    { value: '20', label: 'Sumy' },
  ];
  const languages: { label: string, value: string }[] = [
    { value: 'en', label: 'English' },
    { value: 'uk', label: 'Ukrainian' },
    { value: 'ru', label: 'Russian' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'es', label: 'Spanish' },
    { value: 'it', label: 'Italian' },
    { value: 'ja', label: 'Japanese' },
    { value: 'ko', label: 'Korean' },
  ];
  const services: { label: string, value: string }[] = [
    { value: '1', label: 'Coming soon!' }
  ];
  const defaultSearch: QuickSearchFormData = {
    country: countries[0].value,
    city: cities[0].value,
    language: languages[0].value,
    service: services[0].value
  };

  useEffect(() => {
    loadInterpretersGrid();
  }, []);

  const loadInterpretersGrid = async () => {
    const results = await UserController.getHomepageList();
    setInterpreters(results.data);
  };
  // const loadModalData = async () => {
  //   setLoadingModelData(true);
  //   try {
  //     const results = await MappingController.getMappings(authContext.account!.id, authContext.location!.id, { mappingKey: props.mappingKey });

  //     setModalData(results.data[0]);
  //   } catch (error) {
  //     NotificationUtil.error({
  //       key: 'MappingModal',
  //       message: 'Error while loading Mapping data',
  //       error
  //     });
  //   }
  //   setLoadingModelData(false);
  // };

  const handleOnFinish = async (values: QuickSearchFormData) => {
    // Thinking we can just send them to search page
  };

  return (
    <Layout className='home-layout'>
      <Content className='expandable-container'>
        <Header className='home-header'>
          <Menu
            mode="horizontal"
            items={[
              { key: '1', label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Home</NavLink> },
              { key: '2', label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Services</NavLink> },
              { key: '3', label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>On-Demand</NavLink> },
              { key: '4', label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>About Us</NavLink> },
              { key: '5', label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Contact Us</NavLink> },
              { key: '6', label: <NavLink to={{ pathname: RouteConfig.LINK.LOGIN }}>Login</NavLink> }
            ]}
          />
        </Header>
        <Content className='content-container'>
          {/* <img className='header-image' src={headerImage} /> */}

          <div className='with-grid-layout-half header-container'>
            <div className='left-side'></div>
            <Row className='right-side with-padding-very-large' align='middle'>
              <div>
                <h1>Interpreting Services</h1>
                <p className='with-special-paragraph'>Interpreting services available 24/7 for any industry, in any language! Consecutive interpretation, simultaneous interpretation, sight interpretations, and more.</p>
              </div>
            </Row>
          </div>

          {/* Quick search */}
          <div className='quick-search-container'>
            <Form
              size='large'
              layout='vertical'
              requiredMark={false}
              form={form}
              initialValues={defaultSearch}
              onFinish={handleOnFinish}
            >
              <Space direction='horizontal' size={24}>
                <Form.Item
                  label='Country'
                  name={NameOf<QuickSearchFormData>('country')}
                  rules={[
                    { required: true, message: 'Country is required' }
                  ]}>
                  <Select options={countries} placeholder='Country' />
                </Form.Item>
                <Form.Item
                  label='City'
                  name={NameOf<QuickSearchFormData>('city')}
                  rules={[
                    { required: true, message: 'City is required' }
                  ]}>
                  <Select options={cities} placeholder='City' />
                </Form.Item>
                <Form.Item
                  label='Service'
                  name={NameOf<QuickSearchFormData>('service')}
                  rules={[
                    { required: true, message: 'Service is required' }
                  ]}>
                  <Select options={services} placeholder='Service' />
                </Form.Item>
                <Form.Item
                  label='Language'
                  name={NameOf<QuickSearchFormData>('language')}
                  rules={[
                    { required: true, message: 'Language is required' }
                  ]}>
                  <Select options={languages} placeholder='Language' />
                </Form.Item>

                <Form.Item label='&nbsp;'>
                  <Button type='primary' htmlType='submit' block>Find</Button>
                </Form.Item>
              </Space>
            </Form>
          </div>

          {/* Interpreters Grid */}
          <div className='our-interpreters'>
            <h1>Our Interpreters and Guides</h1>
            {interpreters.map((x, i) => {
              return <Space key={i} direction='vertical' style={{ paddingLeft: 8, paddingRight: 8 }}>
                <Card
                  hoverable
                  bodyStyle={{backgroundColor: '#F2F1F2'}} /* Please fix in future */
                  style={{ width: 220, display: 'inline-block'}}
                  cover={<Image style={{
                    display: 'block',
                    maxWidth: 230,
                    maxHeight: 200,
                    width: 'auto',
                    height: 'auto',
                  }} src={StringUtil.IsNullOrEmpty(x.profilePictureUrl) ? 'error' : x.profilePictureUrl} fallback={imageNotFound} />}
                >
                  <h3>{x.displayName}</h3>
                  <p>{x.languageProficiencies.map(x => x.languageName)?.join(', ')}</p>
                  {/* <Space direction='horizontal'>{x.languages.map((y, ii) => (<span key={ii}>{y}, </span>))}</Space> */}
                  <Link to={RouteConfig.PUBLIC_VIEW_INTERPRETER(x.id)}>View Profile</Link>
                </Card>
              </Space>;
            })}
          </div>

          {/* Join us section */}
          <h1 className='with-text-align-center with-margin-top-large without-margin-bottom'>Join Our Team</h1>
          <div className='with-grid-layout-half'>
            <img className='join-team-image' src={joinTeamImage} />
            <Row align='middle'>
              <p className='with-special-paragraph with-padding-very-large'>Fill your information and join our team. This site is dedicated to helping travelers and businesses find professional, affordable, and reliable interpreters, as well as personal guides in the country of Ukraine. Professionalism and integrity are the cornerstones of our business.</p>
            </Row>
          </div>
        </Content>
      </Content>
      <StandardFooter />
    </Layout>
  );
};

export default HomeLayout;
