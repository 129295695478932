import axios from 'axios';

/**
 * Utility used to format errors. Really only one thing here, so a function would probably have been a better idea. Oh well
 */
class ErrorUtil {
  public GetMessageFromError(error: unknown) {
    // Idk what I am doing with this. There is error code all over the app at this point
    if (axios.isAxiosError(error)) {
      // Error now becomes an axios error through typescript magic
      const data = error.response?.data as any;
      return data != null ? data.message ?? data.description ?? 'An error has occurred.' : error.message;
    }
    return (error as Error)?.message ?? String(error);
  }
}

export default new ErrorUtil();
