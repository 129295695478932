import React, { useContext, useEffect, useState } from 'react';
import { Button, DatePicker, Form, Image, Input, message, Modal, Select, Skeleton, Switch, Tooltip, Upload, UploadProps } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, UploadOutlined } from '@ant-design/icons';
import { NameOf, StringUtil } from 'src/utils';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import NotificationUtil from 'src/utils/NotificationUtil';
import UserProfileDTO from 'src/models/generated/UserProfileDTO';
import { PasswordResetModal } from 'src/components/core/passwordResetModal';
import PhoneVerificationModal from 'src/components/core/PhoneVerificationModal';
import AccountController from 'src/api/AccountController';
import ProfileController from 'src/api/ProfileController';
import { UploadFile } from 'antd/lib/upload/interface';
import UploadMediaDTO from 'src/models/generated/UploadMediaDTO';

interface ProfileHomeFormData extends UserProfileDTO {
}

const EditProfilePage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const [form] = Form.useForm<ProfileHomeFormData>();
  const [formData, setFormData] = useState<Partial<UserProfileDTO>>({});
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showPhoneVerificationModal, setShowPhoneVerificationModal] = useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);

  // Upload component
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);

  // This forces the given values to update in realtime, so we can do something with that value in the form itself
  // const tfaEnabledValue = Form.useWatch(NameOf<ProfileHomeFormData>('tfaEnabled'), form);
  // const phoneNumberValue = Form.useWatch(NameOf<ProfileHomeFormData>('phoneNumber'), form);

  useEffect(() => {
    loadForm();
  }, []);

  const loadForm = async () => {
    setLoading(true);
    const profileResult = await AccountController.getCurrentUserProfile();

    setFormData(profileResult.data);
    setLoading(false);
  };

  const onFinish = async (values: UserProfileDTO) => {
    try {
      setSubmitting(true);
      const result = await AccountController.updateCurrentUserProfile(values);

      // Update the current profile data
      await authContext.updateProfile(values);
      // setFormData(result.data);
      // form.resetFields();

      NotificationUtil.success({
        key: 'ProfilePage',
        message: 'Profile',
        description: 'Profile has been updated'
      });
    } catch (error) {
      NotificationUtil.error({
        key: 'ProfilePage',
        message: 'Profile',
        description: 'Error while saving profile',
        error
      });
    }
    setSubmitting(false);
  };

  const renderUploadFile = () => {
    const handleUpload = async () => {
      const formData = new FormData();
      fileList.forEach((file) => {
        // formData.append('files[]', file as RcFile);
        formData.append('files[]', file as any);
      });
      setUploading(true);

      try {
        const results = await AccountController.uploadProfilePicture(UploadMediaDTO.create({file: fileList[0]}));
        message.success('upload successfully.');
      } catch (error) {
        message.error('upload failed.' + error);
      }
      setUploading(false);
    };

    const props: UploadProps = {
      onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        setFileList([...fileList, file]);

        return false;
      },
      fileList,
    };

    return (
      <>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? 'Uploading' : 'Upload Profile Picture'}
        </Button>
      </>
    );
  };

  const renderContent = () => {
    return (<>
    <Image
      width={200}
      src={formData.profileUrl}
    />
      <Form
        size='large'
        layout='vertical'
        requiredMark={false}
        form={form}
        initialValues={formData}
        onFinish={onFinish}
      >
        {/* First Name */}
        <Form.Item
          label='First Name'
          name={NameOf<ProfileHomeFormData>('firstName')}
          rules={[
            { required: true, message: 'First Name is required' }
          ]}>
          <Input allowClear />
        </Form.Item>

        {/* Middle Name */}
        <Form.Item
          label='Middle Name'
          name={NameOf<ProfileHomeFormData>('middleName')}>
          <Input allowClear />
        </Form.Item>

        {/* Last Name */}
        <Form.Item
          label='Last Name'
          name={NameOf<ProfileHomeFormData>('lastName')}
          rules={[
            { required: true, message: 'Last Name is required' }
          ]}>
          <Input allowClear />
        </Form.Item>

        {/* Gender */}
        <Form.Item
          label='Gender'
          name={NameOf<ProfileHomeFormData>('gender')}
          rules={[
            { required: true, message: 'Gender is required' }
          ]}>
          <Select options={[{ value: 'Male' }, { value: 'Female' }, { value: 'Prefer Not to Answer' }]} />
        </Form.Item>

        {/* Email address */}
        <Form.Item
          label='Email Address'
          name={NameOf<ProfileHomeFormData>('emailAddress')}
          rules={[
            { required: true, message: 'Email Address is required' }
          ]}>
          <Input />
        </Form.Item>

        {/* Phone Number */}
        <Form.Item
          label='Phone Number'
          name={NameOf<ProfileHomeFormData>('phoneNumber')}
        >
          <Input />
        </Form.Item>

        {/* Date of Birth */}
        <Form.Item
          label='Date of Birth'
          name={NameOf<ProfileHomeFormData>('dateOfBirth')}
          rules={[
            { required: true, message: 'Date of Birth is required' }
          ]}>
          <DatePicker />
        </Form.Item>

        {/* Two-Factor Authentication */}
        {/* <Form.Item
              label='TFA Selection'
              name={NameOf<ProfileHomeFormData>('gender')}
              rules={[
                { required: true, message: 'Gender is required' }
              ]}>
              <Select options={[{ value: 'Off' }, { value: 'Female' }, { value: 'Prefer Not to Answer' }]} />
            </Form.Item> */}


        {/* Change Password */}
        {/* <Form.Item>
          <Button style={{ padding: '0 20px' }} disabled={submitting}>Change Password</Button>
        </Form.Item> */}

        {/* Profile Upload */}
        {renderUploadFile()}

        {/* Submit */}
        <Form.Item>
          <Button type='primary' htmlType="submit" style={{ padding: '0 20px' }} loading={loading || submitting}>Save</Button>
        </Form.Item>
      </Form>
    </>);
  };

  return (
    <div className='profile-home standard-form-layout'>
      <h1>User Profile</h1>

      {loading ? <Skeleton active /> : renderContent()}
    </div>
  );
};

export default EditProfilePage;
