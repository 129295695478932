import React, { useContext, useEffect, useState } from 'react';
import { Button, Skeleton, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { NameOf, StringUtil, TableColumnBuilder } from 'src/utils';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import AdminUserController from 'src/api/AdminUserController';
import AdminSimpleUserResponse from 'src/models/generated/AdminSimpleUserResponse';
import RouteConfig from 'src/config/RouteConfig';

const AdminUsersListPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const [tableData, setTableData] = useState<AdminSimpleUserResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // TODO: Please consolidate Roles
  const allRoles = [
    { label: 'Administrator', value: 1 },
    { label: 'Agent', value: 2 },
    { label: 'Interpreter', value: 3 },
    { label: 'Customer', value: 4 },
  ];

  // // Modal
  // const [editNameModalValue, setEditNameModalValue] = useState<AdminSimpleUserResponse>();
  // const [editNameModalVisible, setEditNameModalVisible] = useState(false);

  // This forces the given values to update in realtime, so we can do something with that value in the form itself
  // const tfaEnabledValue = Form.useWatch(NameOf<ProfileHomeFormData>('tfaEnabled'), form);
  // const phoneNumberValue = Form.useWatch(NameOf<ProfileHomeFormData>('phoneNumber'), form);

  useEffect(() => {
    loadTable();
  }, []);

  const loadTable = async () => {
    setLoading(true);
    const profileResult = await AdminUserController.listUsers();

    setTableData(profileResult.data);
    setLoading(false);
  };

  const handleEditClick = (record: AdminSimpleUserResponse) => {
    // setEditNameModalValue(record);
    // setEditNameModalVisible(true);
  };

  const tableColumns: ColumnProps<AdminSimpleUserResponse>[] = [
    TableColumnBuilder.Create<AdminSimpleUserResponse>('id', 'Id')
      .Width(70)
      .AddSorter('Number')
      .AddRenderer('Text')
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>('firstName', 'First Name')
      .Width(120)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>('lastName', 'Last Name')
      .Width(120)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>('emailAddress', 'Email')
      // .Width(200)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>('role', 'Role')
      .Width(120)
      .AddSorter('Text')
      .AddRenderer('Text')
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>('isApproved', 'Approved')
      .Width(100)
      .AddSorter('Boolean')
      .AddRenderer('Boolean', 'YesNo')
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>('isActive', 'Active')
      .Width(100)
      .AddSorter('Boolean')
      .AddRenderer('Boolean', 'YesNo')
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>('publicId', 'Public Id')
      .Width(150)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>()
      .Key('Edit')
      .Width(50)
      // .AddRenderer('Custom', (_, record) => (<Button type='link' icon={<EditOutlined />} onClick={() => handleEditClick(record)} />))
      .AddRenderer('Custom', (value, record) => {
        return <Link to={RouteConfig.ADMIN_USERS_EDIT(record.id)}>Edit</Link>;
      })
      .Build(),
  ];

  const renderContent = () => {
    return (<>
      <Table
        style={{ marginTop: 12, maxWidth: 1200 }}
        rowKey={NameOf<AdminSimpleUserResponse>('id')}
        className='condensed-table striped-table borderless-table'
        rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
        pagination={{defaultPageSize: 50, hideOnSinglePage: true}}
        loading={loading}
        columns={tableColumns}
        dataSource={tableData}
      />
    </>);
  };

  return (
    <div className='admin-users-page'>
      <h1>Users List Page</h1>
      <Link to={RouteConfig.ADMIN_USERS_ADD()}>
        <Button type='primary'>Add New User</Button>
      </Link>
      <Button type='primary' onClick={() => AdminUserController.test()}>Test Something</Button>
      {loading ? <Skeleton active /> : renderContent()}
    </div>
  );
};

export default AdminUsersListPage;
