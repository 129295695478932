import React, { useEffect, useRef, useState } from 'react';
import { Button, message, Space, Typography } from 'antd';
import { useId } from 'src/hooks/UseId';
import { useInterval } from 'src/hooks/UseInterval';

export interface DescriptionType {
  label: React.ReactNode;
  valueFunc: () => React.ReactNode;
  showWhenReduced?: boolean;
  reloadInterval?: 'minute' | 'second' | '5 second' | '5 minute' | 'none';
}

export interface DebugOverlayItemProps {
  items: DescriptionType[];

  reduced?: boolean;
  startReduced?: boolean;
  onClick?: () => void;
}

/**
 * Shows debug information as an overlay on the UI
 */
const DebugOverlayItem: React.FC<DebugOverlayItemProps> = (props) => {
  const id = useId();
  // Indicates that the item has been clicked into reduced state
  const [isReduced, setIsReduced] = useState(props.reduced ?? props.startReduced ?? false);
  const [intervalCounter, setIntervalCounter] = useState(0);

  useEffect(() => {
    if (props.reduced != null) {
      setIsReduced(props.reduced);
    }
  }, [props.reduced]);

  const handleMoreClick = () => {
    if (props.reduced == null) {
      setIsReduced(!isReduced);
    }
    props.onClick && props.onClick();
  };

  const handleContentDoubleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    // TODO: Add some other way to check for clipboard support
    if (navigator.clipboard) {
      const elementText = (event.target as Node).textContent;
      if (elementText == null) {
        console.log('[DebugOverlayItem] Could not copy text. Target unknown type', event);
        return;
      }
      navigator.clipboard.writeText(elementText);
      message.info(`Copied to clipboard: ${elementText}`);
    } else {
      console.log('[DebugOverlayItem] Clipboard not supported, sorry', navigator.clipboard);
    }
  };

  const handleCopyClick = (event?: React.MouseEvent<HTMLDivElement>) => {
    if (event == null) {
      return;
    }
    message.info('Copied to clipboard!');
  };

  if (props.items == null || props.items.length < 1) {
    return null;
  }

  // Add an interval to refresh occasionally
  const interval = props.items.map<number | null>(x => {
    switch (x.reloadInterval) {
      case '5 minute':
        return 300000;
      case 'minute':
        return 60000;
      case '5 second':
        return 5000;
      case 'second':
        return 1000;
      default:
        return 300000;
      // return null;
    }
  }).reduce((a, b) => Math.min(a ?? 300000, b ?? 300000));

  useInterval(() => {
    setIntervalCounter(prev => prev + 1);
  }, interval);

  const renderItems = isReduced ? props.items.filter(x => x.showWhenReduced) : props.items;
  const shouldShowMore = renderItems.some(x => x.showWhenReduced);
  const renderedItems = renderItems.map((item, index) => (
    <Space key={id + index}>
      <Typography.Text strong className='inner-title'>{item.label}</Typography.Text>
      <Typography.Text copyable={{ onCopy: handleCopyClick }} className='inner-content'>{item.valueFunc()}</Typography.Text>
      {/* <span className='inner-content' onDoubleClick={handleContentDoubleClick}>{item.valueFunc()}</span> */}
    </Space>));

  return <>
    <Space direction='vertical' size={0}>
      {/* If the default is set to reduced, but there are no elements to show, we atleast want to always show the 'More' button */}
      {isReduced && <Button className='more-less-button' type='link' onClick={handleMoreClick}>More</Button>}
      {shouldShowMore && !isReduced && <Button className='more-less-button' type='link' onClick={handleMoreClick}>Less</Button>}
      {renderedItems}
    </Space>
  </>;
};

export default DebugOverlayItem;
