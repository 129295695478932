
/** Handles the internal workings for the routes file */
export class RouteUrl {
    /**
     * Returns the original URL provided before adding in the params
     */
    public readonly originalUrl: string;
    /**
     * Returns the original Params as an object
     */
    public readonly originalParams?: Record<string, unknown>;

    /**
     * Returns the route generated from the URL and Params
     */
    public toString(): string {
        // Dev Note: We might be able to use react-router's 'generatePath()' instead
        // import { generatePath } from 'react-router'
        return this.generateRoute();
    }

    /**
     * Creates an instance of RouteUrl
     * @example
     * // Simple
     * RouteUrl('/path/:id', { id });
     *
     * // Complicated. Notice the matching keys and url params
     * RouteUrl('/path/:foo/:bar/:baz?', { foo, bar: id, baz: 'something' });
     *
     * @remarks
     * If params are in the URL and **not** passed into the params param, then the route is not parsed and will equal the url passed in
     *
     * @param {string} url The url as a React Router url string, see examples
     * @param {{}} [params] The params for the url as an object. Items must be keyed corresponding to their name in the url
     */
    constructor(url: string, params?: Record<string, unknown>) {
        // Remove spaces from url
        this.originalUrl = url.replace(/(\r\n|\n|\r| )/gm, '');
        this.originalParams = params; // as { [index: string]: string };
    }

    private generateRoute(): string {
        // If we have no params, skip parsing.
        if (this.originalParams == null || Object.keys(this.originalParams).length < 1) {
            return this.originalUrl;
        }

        const paramKeys = Object.keys(this.originalParams);

        // Purely for readability on the return statement below
        const mapFunction = (split: string): string => {
            let isOptional = false;

            // Check if variable or just string
            if (split[0] !== ':') {
                return split;
            }

            // Remove the ':' from the start of the key
            split = split.slice(1);

            // Check if required
            if (split.slice(-1) === '?') {
                isOptional = true;
                split = split.slice(0, -1);
            }

            // Check if params match. If not, optional params just get let through
            let key = paramKeys.find(y => y === split);
            if (key == null && isOptional) {
                return '';
            } else if (key == null) {
                key = ''; // Dev Note: Probably should throw an error here
            }

            return String(this.originalParams![key]);
        };

        return '/' + this.originalUrl
            .split('/') // Split into chunks
            .map(mapFunction) // Map params to variables
            .filter(x => x != null && x.trim().length > 0) // Remove empty
            .join('/'); // Join everything back together again
    }
}