import RouteConfig from './RouteConfig';
import PageInterface from '../core/PageInterface';

// Layouts
import UserLayout from 'src/layouts/UserLayout';
import PublicLayout from 'src/layouts/PublicLayout';
import HomeLayout from 'src/layouts/HomeLayout';
import CenteredLayout from 'src/layouts/CenteredLayout';
import DevLayout from 'src/layouts/DevLayout';

/* Pages */
import HomePage from 'src/pages/HomePage';
import StyleGuide from 'src/pages/0Dev/StyleGuide';
import DevelopmentTestingPage from 'src/pages/0Dev/DevelopmentTestingPage';
import LoginPage from 'src/pages/login/LoginPage';

// Admin Pages
import AdminUsersListPage from 'src/pages/admin/AdminUsersListPage';
import AdminUsersAddPage from 'src/pages/admin/AdminUsersAddPage';
import AdminUsersEditPage from 'src/pages/admin/AdminUsersEditPage';

// Login
// import LoginEmailPage from 'src/pages/login/LoginEmailPage';
// import LogoutPage from 'src/pages/login/Logout';

// User Facing
import InterpreterProfilePage from 'src/pages/interpreter/InterpreterProfilePage';

// Profile
import ProfilePage from 'src/pages/profile/ProfilePage';
import EditProfilePage from 'src/pages/profile/EditProfilePage';
// import ProfileResetPasswordPage from 'src/pages/profile/ProfileResetPasswordPage';
// import ProfileResetPasswordConfirmPage from 'src/pages/profile/ProfileResetPasswordConfirmPage';
// import ProfileVerifyPhoneRequestPage from 'src/pages/profile/ProfileVerifyPhoneRequestPage';


class NavigationPages {
  public MyPages: PageInterface[] = [
    // Core Pages
    { key: 'home',              route: RouteConfig.LINK.HOME,             publicComponent: HomePage, privateComponent: HomePage,  publicLayout: HomeLayout, privateLayout: HomeLayout }, // This wont actually render the components, just the layout given how the home layout works
    { key: 'user-home',         route: RouteConfig.LINK.USER_HOME,        privateComponent: HomePage, privateLayout: UserLayout },
    { key: 'interpreter-home',  route: RouteConfig.LINK.INTERPRETER_HOME, privateComponent: HomePage, privateLayout: UserLayout },

    // Login Pages
    { key: 'login', route: RouteConfig.LINK.LOGIN, privateComponent: LoginPage, publicComponent: LoginPage, publicLayout: CenteredLayout },

    // Profile
    { key: 'profile-home', route: RouteConfig.LINK.PROFILE, privateComponent: EditProfilePage, privateLayout: UserLayout },
    // { key: 'profile-reset-password',              route: RouteConfig.LINK.PROFILE_RESET_PASSWORD,         privateComponent: ProfileResetPasswordPage,        privateLayout: PublicLayout },
    // { key: 'profile-reset-password-confirmation', route: RouteConfig.LINK.PROFILE_RESET_PASSWORD_CONFIRM, privateComponent: ProfileResetPasswordConfirmPage, privateLayout: PublicLayout },
    // { key: 'profile-verify-phone',                route: RouteConfig.LINK.PROFILE_VERIFY_PHONE,           privateComponent: ProfileVerifyPhoneRequestPage,   privateLayout: PublicLayout },

    // Misc
    // { key: 'select-account',    route: RouteConfig.LINK.SELECT_ACCOUNT,     privateComponent: SelectAccountPage,    privateLayout: UserLayout },

    // User Facing Pages
    { key: 'public-view-interpreter', route: RouteConfig.LINK.PUBLIC_VIEW_INTERPRETER, publicComponent: InterpreterProfilePage, privateLayout: PublicLayout },

    // Admin Pages
    { key: 'admin-list-users',    route: RouteConfig.LINK.ADMIN_LIST_USERS, privateComponent: AdminUsersListPage,     privateLayout: UserLayout },
    { key: 'admin-add-user',      route: RouteConfig.LINK.ADMIN_USERS_ADD,  privateComponent: AdminUsersAddPage,      privateLayout: UserLayout },
    { key: 'admin-edit-user',     route: RouteConfig.LINK.ADMIN_USERS_EDIT, privateComponent: AdminUsersEditPage,     privateLayout: UserLayout },

    // Development Pages
    { key: 'style-guide',             route: RouteConfig.LINK.STYLE_GUIDE,             publicComponent: StyleGuide },
    { key: 'testing-user-home',       route: RouteConfig.LINK.TESTING_HOME,            publicComponent: DevelopmentTestingPage, publicLayout: DevLayout },
    { key: 'testing-user-layout',     route: RouteConfig.LINK.TESTING_USER_LAYOUT,     publicComponent: DevelopmentTestingPage, publicLayout: UserLayout },
    { key: 'testing-centered-layout', route: RouteConfig.LINK.TESTING_CENTERED_LAYOUT, publicComponent: DevelopmentTestingPage, publicLayout: CenteredLayout },
    { key: 'testing-public-layout',   route: RouteConfig.LINK.TESTING_PUBLIC_LAYOUT,   publicComponent: DevelopmentTestingPage, publicLayout: PublicLayout },
  ];

  constructor() {
    // If we are running in dev, we really want to report key errors if the dev is paying attention
    if (process.env.NODE_ENV === 'development') {
      const keys = this.MyPages.map(x => x.key);
      const keySet = new Set(keys[0]);

      keys.forEach(key => {
        const previousSize = keySet.size;
        keySet.add(key);
        if (keySet.size === previousSize) {
          console.error(`Warning! Duplicate key found in the ConfigurePages.ts file. Please revise this key: ${key}`);
        }
      });
    }
  }
}

const Pages = new NavigationPages();
export default Pages;
