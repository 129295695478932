import { BaseOptionType } from 'antd/lib/select';
import * as React from 'react';

/**
 * Utility to handle the constants for now. These will eventually be driven by a context, like the authcontext
 */
class ConstantsUtil {
  roles: BaseOptionType[] = [
    { value: 1, label: 'Administrator' },
    { value: 2, label: 'Agent' },
    { value: 3, label: 'Interpreter' },
    { value: 4, label: 'Customer' },
  ];

  languages: BaseOptionType[] = [
    { value: 'en', label: 'English' },
    { value: 'uk', label: 'Ukrainian' },
    { value: 'ru', label: 'Russian' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'es', label: 'Spanish' },
    { value: 'it', label: 'Italian' },
    { value: 'ja', label: 'Japanese' },
    { value: 'ko', label: 'Korean' },
  ];

  proficiencies: BaseOptionType[] = [
    { value: 1, label: 'None' },
    { value: 2, label: 'Basic' },
    { value: 3, label: 'Simple' },
    { value: 4, label: 'Independent' },
    { value: 5, label: 'Complex' },
    { value: 6, label: 'Proficient' },
    { value: 7, label: 'Fluent' },
  ];

  interpretingServices: BaseOptionType[] = [
    {value: 1,  label: 'Interpreting',                    extra: 'interpreting'                    },
    {value: 2,  label: 'Translation',                     extra: 'translation'                     },
    {value: 3,  label: 'Personal Guiding',                extra: 'personal_guiding'                },
    {value: 4,  label: 'Online Interpreting',             extra: 'online_interpreting'             },
    {value: 5,  label: 'Phone Conversations',             extra: 'phone_conversations'             },
    {value: 6,  label: 'Tours/Excursions',                extra: 'tours_excursions'                },
    {value: 7,  label: 'Documents, Specs',                extra: 'documents_specs'                 },
    {value: 8,  label: 'Correspondence Forward',          extra: 'correspondence_forward'          },
    {value: 9,  label: 'Arranging Accommodation',         extra: 'arranging_accommodation'         },
    {value: 10, label: 'Airport Transfer',                extra: 'airport_transfer'                },
    {value: 11, label: 'Train/Plane Tickets Reservation', extra: 'train_plane_tickets_reservation' },
    {value: 12, label: 'Driving/Taxi',                    extra: 'driving_taxi'                    },
    {value: 13, label: 'Cell Phone Rental',               extra: 'cell_phone_rental'               },
    {value: 14, label: 'Flowers & Gifts Delivery',        extra: 'flowers_gifts_delivery'          },
    {value: 15, label: 'Discovery/Investigation',         extra: 'discovery_investigation'         },
    {value: 16, label: 'Advice',                          extra: 'advice'                          },
    {value: 17, label: 'Personal Driver',                 extra: 'personal_driver'                 },
    {value: 18, label: 'Apartment Rentals',               extra: 'apartment_rentals'               },
  ];
}

export default new ConstantsUtil();
