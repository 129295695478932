import * as React from 'react';
import { notification } from 'antd';
import axios, { AxiosError } from 'axios';

/** Based off of the ArgsProps from antd/notification */
export interface NotificationConfig {
  /** Used to help update the notification for a given operation */
  key: string;
  /** The title for the notification */
  message: React.ReactNode;
  description?: React.ReactNode;
  /** Provides easy error rendering */
  error?: Error | AxiosError | unknown;
  /** When true, closes the notification when clicked
   *
   * Default: True
   */
  closeOnClick?: boolean;

  /** The close icon. Can be removed if set to null */
  closeIcon?: React.ReactNode;

  /** "Time in seconds before Notification is closed. When set to 0 or null, it will never be closed automatically"
   *
   * -AntD documentation */
  duration?: number | null;
  className?: string;
  onClose?: () => void;
}

const defaults: Partial<NotificationConfig> = {
  duration: 7,
  closeOnClick: true
};

const handleOnClick = (props: NotificationConfig) => {
  return props.closeOnClick ? () => notification.close(props.key) : undefined;
};

const handleOnClose = (props: NotificationConfig) => {
  return props.onClose ? props.onClose : undefined;
};

/** Renders the description and the error if one exists */
const renderDescription = (props: NotificationConfig) => {
  const description = typeof props.description === 'string' ? <p>{props.description}</p> : props.description;
  const standardErrorMessage = 'An unknown error has occurred';

  const renderError = () => {
    // If no error, return null, which does not render which is perfect
    if (props.error == null) {
      return null;
    }

    // Check for Axios first as this was primarily created for it
    if (axios.isAxiosError(props.error)) {
      // The API provides plenty of user friendly messages, so we can just forward those on to the user, assuming it's just a string
      const data = props.error.response?.data as any;

      if (typeof data === 'object') {
        // Idk, I was having fun here
        return <p>{data.Message ? 'Message: ' : data.Description ? 'Description: ' : null}{data.Message ?? data.Description ?? standardErrorMessage}</p>;
      } else if (typeof data === 'string') {
        return <p>{data}</p>;
      }
    } else if (props.error instanceof Error) {
      const errorMessage = props.error.message ?? standardErrorMessage;
      return <p>Error: {errorMessage}</p>;
    }

    // We cannot be sure what the error is at this point, so just punt
    // Check string first, it could be that
    if (typeof props.error === 'string' || typeof props.error === 'number') {
      return <p>{props.error}</p>;
    }

    // Log the error in dev and warn the developer
    if (process.env.NODE_ENV === 'development') {
      if (typeof props.error === 'function' || typeof props.error === 'object') {
        console.error('NotificationUtil: Error of strange origin was returned and is not supported. Please be warned', { error: props.error, type: typeof props.error });
      }

      return <p>Unknown Error Encountered</p>;
    }

    // Well, error is just unknown, which really shouldn't happen but here we are.
    return null;
  };

  return <div className='custom-error-container'>
    {description}
    {renderError()}
  </div>;
};

const NotificationUtil = {
  success: (props: NotificationConfig) => (
    notification.success({
      ...defaults,
      ...props,
      description: renderDescription(props),
      onClick: handleOnClick(props),
      onClose: handleOnClose(props)
    })
  ),
  error: (props: NotificationConfig) => (
    notification.error({
      ...defaults,
      duration: 0, // We want errors to show forever
      ...props,
      description: renderDescription(props),
      onClick: handleOnClick(props),
      onClose: handleOnClose(props)
    })
  ),
  info: (props: NotificationConfig) => (
    notification.info({
      ...defaults,
      ...props,
      description: renderDescription(props),
      onClick: handleOnClick(props),
      onClose: handleOnClose(props)
    })
  ),
  warn: (props: NotificationConfig) => (
    notification.warn({
      ...defaults,
      ...props,
      description: renderDescription(props),
      onClick: handleOnClick(props),
      onClose: handleOnClose(props)
    })
  ),
  open: (props: NotificationConfig) => (
    notification.open({
      ...defaults,
      ...props,
      description: renderDescription(props),
      onClick: handleOnClick(props),
      onClose: handleOnClose(props)
    })
  ),
  close: (key: string) => notification.close(key)
};

export default NotificationUtil;
