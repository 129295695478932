import { Layout, Menu } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import RouteConfig from 'src/config/RouteConfig';
// import lowerLogo from 'src/assets/company-logo-with-name.svg';
import './PublicLayout.less';
import StandardFooter from './StandardFooter';

const { Content, Header, Footer } = Layout;

const PublicLayout: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <Layout className='public-layout'>
      <Content className='expandable-container'>
        <Header className='public-header'>
          <Menu
            mode="horizontal"
            items={[
              { key: '1', label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Home</NavLink>},
              { key: '2', label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Services</NavLink>},
              { key: '3', label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>On-Demand</NavLink>},
              { key: '4', label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>About Us</NavLink>},
              { key: '5', label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Contact Us</NavLink>}
            ]}
          />
        </Header>
        <Content className='content-container'>
          <div className='inner-container'>
            {props.children}
          </div>
        </Content>
      </Content>
      <StandardFooter />
    </Layout>
  );
};

export default PublicLayout;

// Values for later
/**
 * width of the site: 1450 or so
 * real site is about 1200 with logo and all that
 *
 */