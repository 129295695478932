// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.standard-footer {
  background-color: #162d45;
  color: white;
  height: 140px;
  padding-top: 15px;
  padding-left: 15px;
}
.standard-footer .footer-row {
  height: 100%;
  padding: 0px 32px;
}
.standard-footer .ant-divider {
  color: white;
  border-left-color: white;
}
.standard-footer .anticon {
  border: solid 2px white;
  border-radius: 50%;
  padding: 6px;
  font-size: 18px;
}
.standard-footer .left-side {
  display: flex;
  align-items: center;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/StandardFooter.less"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,YAAA;EAUA,aAAA;EACA,iBAAA;EACA,kBAAA;AAVF;AAJA;EAKI,YAAA;EAGA,iBAAA;AAAJ;AARA;EAkBI,YAAA;EACA,wBAAA;AAPJ;AAZA;EAwBI,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;AATJ;AAlBA;EAgCI,aAAA;EACA,mBAAA;EACA,YAAA;AAXJ","sourcesContent":["@import '/src/variables.less';\n\n.standard-footer {\n  background-color: #162d45;\n  color: white;\n\n  .footer-row {\n    height: 100%;\n\n    // Temporary while I get other things working\n    padding: 0px 32px;\n  }\n\n  // These settings are to handle the floating footer\n  height: @standardFooterHeight;\n  padding-top: 15px;\n  padding-left: 15px;\n\n  // Divider\n  .ant-divider {\n    color: white;\n    border-left-color: white;\n  }\n\n  // Icons\n  .anticon {\n    border: solid 2px white;\n    border-radius: 50%;\n    padding: 6px;\n    font-size: 18px;\n  }\n\n  // Center and handle the items\n  .left-side {\n    display: flex;\n    align-items: center;\n    height: 100%;\n  }\n}\n\n@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: @primary-color;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: #EAE8E9;@table-header-sort-active-bg: #EAE8E9;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
