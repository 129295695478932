// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-table .ant-table-cell.vertical-baseline {
  vertical-align: baseline;
}
.ant-table .ant-table-cell.vertical-sub {
  vertical-align: sub;
}
.ant-table .ant-table-cell.vertical-super {
  vertical-align: super;
}
.ant-table .ant-table-cell.vertical-text-top {
  vertical-align: text-top;
}
.ant-table .ant-table-cell.vertical-text-bottom {
  vertical-align: text-bottom;
}
.ant-table .ant-table-cell.vertical-middle {
  vertical-align: middle;
}
.ant-table .ant-table-cell.vertical-top {
  vertical-align: top;
}
.ant-table .ant-table-cell.vertical-bottom {
  vertical-align: bottom;
}
`, "",{"version":3,"sources":["webpack://./src/utils/core/TableColumnBuilder.less"],"names":[],"mappings":"AAMM;EACE,wBAAA;AALR;AAOM;EACE,mBAAA;AALR;AAOM;EACE,qBAAA;AALR;AAOM;EACE,wBAAA;AALR;AAOM;EACE,2BAAA;AALR;AAOM;EACE,sBAAA;AALR;AAOM;EACE,mBAAA;AALR;AAOM;EACE,sBAAA;AALR","sourcesContent":["// I wasn't too excited to make this less file, but here we are\n\n.ant-table {\n  .ant-table-cell {\n    // Just pulled out all of the aligns from the msdn site\n    &.vertical {\n      &-baseline {\n        vertical-align: baseline;\n      }\n      &-sub {\n        vertical-align: sub;\n      }\n      &-super {\n        vertical-align: super;\n      }\n      &-text-top {\n        vertical-align: text-top;\n      }\n      &-text-bottom {\n        vertical-align: text-bottom;\n      }\n      &-middle {\n        vertical-align: middle;\n      }\n      &-top {\n        vertical-align: top;\n      }\n      &-bottom {\n        vertical-align: bottom;\n      }\n    }\n  }\n}\n\n@primary-color: #187BB3;@menu-icon-size: 22px;@menu-icon-size-lg: 24px;@layout-header-background: @primary-color;@layout-header-color: #FFF;@layout-body-background: #FFF;@layout-sider-background: #0F2F4F;@menu-item-color: @primary-color;@menu-highlight-color: #FFF;@layout-header-padding: 0 16px;@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@divider-color: rgba(0, 0, 0, 15%);@table-row-hover-bg: #EAE8E9;@table-header-sort-active-bg: #EAE8E9;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
