import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import './DevLayout.less';

const { Header, Content, Footer, Sider } = Layout;

/** This layout is 100% aimed at development and testing */
const DevLayout: React.FC<React.PropsWithChildren> = (props) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout className='dev-layout'>
      <Sider className='layout-sider' collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
        <div className="logo" />
        <Menu className='layout-sider-menu' theme="dark" defaultSelectedKeys={['1']} mode="inline" items={[
          { key: '1', label: 'Mike?', icon: <PieChartOutlined /> },
          { key: '2', label: 'No', icon: <DesktopOutlined /> },
          { key: '3', label: 'It\'s Robin', icon: <UserOutlined /> },
          { key: '4', label: 'Mlem', icon: <TeamOutlined /> }
        ]} />
      </Sider>
      <Content>
        <Layout className="content-layout">
          <Header className="layout-header">
            <span className='intro-text'>Account</span>
          </Header>
          <Content className='content-container'>
            {props.children}
            <Footer style={{ textAlign: 'center' }}>Interpreters World ©2022 Created by Joseph Bowman</Footer>
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
};

export default DevLayout;
