import { FunctionMapper, KeyMapper, Omit, StringNum } from '../core/CoreTypes';
import { RouteUrl } from '../core/RouteUrl';

/** Takes all keys from **Routes**, removing prototype, returning the remaining keys with a type of string for each */
type T6 = KeyMapper<Omit<typeof RoutesInternal, 'prototype'>, string>;

type T7 = FunctionMapper<Omit<typeof RoutesInternal, 'prototype'>, string>;

type T8 = T7 & { LINK: T6 };

class RoutesInternal {
  /* General */
  public static HOME() { return new RouteUrl('/'); }
  public static SERVICES() { return new RouteUrl('/services'); }
  public static ON_DEMAND() { return new RouteUrl('/on-demand'); }
  public static ABOUT_US() { return new RouteUrl('/about-us'); }
  public static CONTACT_US() { return new RouteUrl('/contact-us'); }
  public static AGENTS() { return new RouteUrl('/agents'); }

  /* Authentication */
  public static LOGIN() { return new RouteUrl('/login'); }
  public static LOGIN_ACTIVATE() { return new RouteUrl('/login/activate'); }
  public static LOGIN_TWO_FACTOR() { return new RouteUrl('/login/two-factor'); }
  public static LOGIN_FORGOT_PASSWORD() { return new RouteUrl('/login/forgot-password'); }

  public static REGISTER() { return new RouteUrl('/register'); }
  public static LOGOUT() { return new RouteUrl('/logout'); }

  /* User Profile */
  public static PROFILE() { return new RouteUrl('/profile'); }

  /* User */
  public static USER_HOME() { return new RouteUrl('/user/home'); }

  /* Admin Stuff */
  public static ADMIN_LIST_USERS() { return new RouteUrl('/admin/users'); }
  public static ADMIN_USERS_ADD() { return new RouteUrl('/admin/users/add'); } // TODO: Gross, please fix
  public static ADMIN_USERS_EDIT(userId: StringNum) { return new RouteUrl('/admin/users/edit/:userId', { userId }); } // TODO: Gross, please fix

  /* Interpreter */
  // I think that having the core interpreters pages in a separate section works. The normal users can get the normal urls
  // So like, interpreters would see /agent/home while users might see /home

  // These will use 'agent' as a prefix because 'interpreters' will be a listing page
  public static INTERPRETER_HOME() { return new RouteUrl('/agent/home'); }
  public static INTERPRETER_CALENDAR() { return new RouteUrl('/agent/calendar'); }
  public static INTERPRETER_AVAILABILITY() { return new RouteUrl('/agent/availability'); }

  /* User Facing */
  // Idk what I was doing with the above area, something about interpreters? Idk
  // This will be what the user sees
  /** Reminder: userPublicId is a GUID */
  public static PUBLIC_VIEW_INTERPRETER(userPublicId: StringNum) { return new RouteUrl('/interpreter/:userPublicId', { userPublicId }); }



  /* Testing */
  public static TESTING_HOME() { return new RouteUrl('/testing'); }
  public static TESTING_USER_LAYOUT() { return new RouteUrl('/testing/user_layout'); }
  public static TESTING_CENTERED_LAYOUT() { return new RouteUrl('/testing/centered_layout'); }
  public static TESTING_PUBLIC_LAYOUT() { return new RouteUrl('/testing/public_layout'); }


  // UNKNOWN BELOW THIS

  /* Authentication */
  // public static LOGIN_EMAIL() { return new RouteUrl('/login'); }
  // public static LOGIN_ACTIVATE() { return new RouteUrl('/login/activate'); }
  // public static LOGIN_ACTIVATE_SUCCESS() { return new RouteUrl('/login/activate/success'); }
  // public static LOGIN_TWO_FACTOR() { return new RouteUrl('/login/two-factor'); }

  // public static LOGIN_FORGOT_PASSWORD() { return new RouteUrl('/login/forgot-password'); }

  // public static LOGIN_TERMS_OF_SERVICE() { return new RouteUrl('/terms-of-service'); }
  // public static LOGIN_PRIVACY_STATEMENT() { return new RouteUrl('/privacy-statement'); }

  // public static LOGOUT() { return new RouteUrl('/logout'); }

  // /* User Profile */
  // public static PROFILE_HOME() { return new RouteUrl('/profile'); }
  // public static PROFILE_RESET_PASSWORD() { return new RouteUrl('/profile/reset-password'); }
  // public static PROFILE_RESET_PASSWORD_CONFIRM() { return new RouteUrl('/profile/reset-password/confirmation'); }
  // public static PROFILE_VERIFY_PHONE() { return new RouteUrl('/profile/verify-phone'); }

  /* Misc */

  /* Dev */
  public static STYLE_GUIDE() { return new RouteUrl('/style-guide'); }

  /* Error Handling */
  public static ERROR_PAGE() { return new RouteUrl('/the-handler'); }
  public static PROD_ERROR_PAGE() { return new RouteUrl('/errors'); }
  public static PAGE_NOT_FOUND() { return new RouteUrl('/404'); }
}

const generateRoutes = (): T8 => {
  const getOriginalURL = function (key: string): string {
    const RoutesNoAny = RoutesInternal as any;
    // If function, run it and assume RouteUrl as a return type. Else, check for string and return its result
    if (typeof RoutesNoAny[key] === 'function') {
      const obj: RouteUrl = RoutesNoAny[key]({});
      if (obj != null) {
        return obj.originalUrl;
      }
    } else if (typeof RoutesNoAny[key] === 'string') {
      return RoutesNoAny[key];
    }
    // Uh
    return '';
  };

  const getStringVariant = function (key: string) {
    const RoutesNoAny = RoutesInternal as any;
    if (typeof RoutesNoAny[key] === 'function') {
      return (...args: any[]) => {
        // Actually call the function, which will return a RouteUrl or a string
        let obj = RoutesNoAny[key](...args) as RouteUrl;
        if (obj != null) {
          return obj.toString();
        } else {
          throw new Error(`Non RouteUrl detected. Please use RouteUrl for this key: ${key}`);
        }
      };
    } else if (typeof RoutesNoAny[key] === 'string') {
      return (...args: any[]) => {
        return RoutesNoAny[key](...args) as string;
      };
    }
    // Not a function, so what do we do?
    return null;
  };

  // Keys to be removed. Object functions, prototype and where we are storing the rest
  const spareKeys = ['name', 'length', 'construct', 'prototype', 'LINK'];

  const keys = Object.getOwnPropertyNames(RoutesInternal) // Get all properties from our routes list
    .filter(x => !spareKeys.includes(x)); // Remove props that we don't use

  // Get the LINK representation. Useful for url matching, such as the sidenav/menu
  const routeLinks = keys
    .map(x => ({ [x]: getOriginalURL(x) }))
    .reduce((a, b) => ({ ...a, ...b })) as T6;

  const routeToStrings = keys
    .map(x => ({ [x]: getStringVariant(x) }))
    .reduce((a, b) => ({ ...a, ...b })) as T7;

  return { ...routeToStrings, LINK: routeLinks };
};

const RouteConfig = generateRoutes();
export default RouteConfig;
