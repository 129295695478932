import React from 'react';
import { Button, Checkbox, Col, Divider, Input, Radio, Row, Space, notification } from 'antd';
import { Link } from 'react-router-dom';
import RouteConfig from 'src/config/RouteConfig';

const DevelopmentTestingPage: React.FC = () => {

  return (
    <div>
      <h1>Testing Page</h1>

      <Row gutter={[8, 8]}>
        <Col>
          <Link to={RouteConfig.LINK.TESTING_PUBLIC_LAYOUT}>
            <Button type='primary'>Public</Button>
          </Link>
        </Col>
        <Col>
          <Link to={RouteConfig.LINK.TESTING_CENTERED_LAYOUT}>
            <Button type='primary'>Centered</Button>
          </Link>
        </Col>
        <Col>
          <Link to={RouteConfig.LINK.TESTING_USER_LAYOUT}>
            <Button type='primary'>User</Button>
          </Link>
        </Col>
      </Row>

    </div>
  );
};

export default DevelopmentTestingPage;
