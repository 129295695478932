import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Col, Collapse, DatePicker, Form, Image, Input, message, Rate, Row, Select, Skeleton, Space, Table, Upload, UploadFile, UploadProps } from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { ConstantsUtil, DisableAutoCompleteTags, NameOf, NotificationUtil, ObjectUtil, StringUtil, TableColumnBuilder } from 'src/utils';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import AdminUserController from 'src/api/AdminUserController';
import { useNavigate, useParams } from 'react-router-dom';
import RouteConfig from 'src/config/RouteConfig';
import AdminAddUpdateUserRequest from 'src/models/generated/AdminAddUpdateUserRequest';
import AddressDTO from 'src/models/generated/AddressDTO';
import LanguageProficiencySelector from 'src/components/LanguageProficiencySelector';
import AdminAddUserRequest from 'src/models/generated/AdminAddUserRequest';

interface AdminUsersAddEditFormData extends AdminAddUserRequest {
}

const AdminUsersAddPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const [form] = Form.useForm<AdminUsersAddEditFormData>();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<AdminAddUserRequest>();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const allRoles = ConstantsUtil.roles;

  useEffect(() => {
    // loadForm();
  }, []);

  const onFinish = async (values: AdminAddUserRequest) => {
    setSubmitting(true);
    try {
      const request = AdminAddUpdateUserRequest.create(ObjectUtil.TrimValues(values));
      const result = await AdminUserController.createUser(request);

      NotificationUtil.success({
        key: 'AdminUsersAddPage',
        message: 'User Add',
        description: 'User has been updated'
      });
      navigate(RouteConfig.ADMIN_LIST_USERS());
    } catch (error) {
      NotificationUtil.error({
        key: 'AdminUsersAddPage',
        message: 'User Add',
        description: 'Error while saving User',
        error
      });
    }
    setSubmitting(false);
  };

  return (
    <div className='admin-users-page'>
      <h1>Add User Page</h1>
      <Skeleton active loading={loading}>
        <Form
          size='middle'
          layout='horizontal'
          labelCol={{ flex: '150px' }}
          // We might also consider just a static form wrapper width of like 400 instead of "responsive"
          wrapperCol={{ sm: 18, md: 14, lg: 12, xl: 10, xxl: 8 }}
          form={form}
          initialValues={formData}
          onFinish={onFinish}
          autoComplete='off'
        >
          {/* First Name */}
          <Form.Item
            label='First Name'
            name={NameOf<AdminUsersAddEditFormData>('firstName')}
            rules={[
              { required: true, message: 'First Name is required' }
            ]}>
            <Input allowClear />
          </Form.Item>

          {/* Middle Name */}
          <Form.Item
            label='Middle Name'
            name={NameOf<AdminUsersAddEditFormData>('middleName')}>
            <Input allowClear />
          </Form.Item>

          {/* Last Name */}
          <Form.Item
            label='Last Name'
            name={NameOf<AdminUsersAddEditFormData>('lastName')}
            rules={[
              { required: true, message: 'Last Name is required' }
            ]}>
            <Input allowClear />
          </Form.Item>

          {/* Gender */}
          <Form.Item
            label='Gender'
            name={NameOf<AdminUsersAddEditFormData>('gender')}
            rules={[
              { required: true, message: 'Gender is required' }
            ]}>
            <Select options={[{ value: 'Male' }, { value: 'Female' }, { value: 'Prefer Not to Answer' }]} />
          </Form.Item>

          {/* Email address */}
          <Form.Item
            label='Email Address'
            name={NameOf<AdminUsersAddEditFormData>('emailAddress')}
            rules={[
              { required: true, message: 'Email Address is required' }
            ]}>
            <Input />
          </Form.Item>

          {/* Phone Number */}
          <Form.Item
            label='Phone Number'
            name={NameOf<AdminUsersAddEditFormData>('phoneNumber')}
          >
            <Input />
          </Form.Item>

          {/* Role */}
          <Form.Item
            label='Role'
            name={NameOf<AdminUsersAddEditFormData>('roleId')}
            rules={[
              { required: true, message: 'Role is required' }
            ]}>
            <Select options={allRoles} />
          </Form.Item>

          {/* Password */}
          <Form.Item
            label='Password'
            name={NameOf<AdminUsersAddEditFormData>('password')}
          >
            <Input.Password allowClear {...DisableAutoCompleteTags()} />
          </Form.Item>

          {/* Submit */}
          <Space  style={{ marginTop: 12 }}>
            <Button type='primary' htmlType="submit" style={{ padding: '0 20px' }} loading={loading || submitting}>Create</Button>
          </Space>
        </Form>
      </Skeleton>
    </div>
  );
};

export default AdminUsersAddPage;
