import React, { useContext, useState } from 'react';
import { Button, ButtonProps, Descriptions, Space } from 'antd';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { LocalStorageUtil, ObjectUtil } from 'src/utils';
import './DebugOverlay.less';
import DebugOverlayItem, { DescriptionType } from './DebugOverlayItem';
import DebugPortal from './DebugPortal';

interface DebugOverlayProps {
}

/**
 * Shows debug information as an overlay on the UI
 */
const DebugOverlay: React.FC<DebugOverlayProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [windowVisible, setWindowVisible] = useState(false);

  const handleWindowClose = () => {
    // Set window closed and also close the debug panel, user can reopen it
    // Dev Note: I thought about it and if the developer closes the window, they probably dont want the panel to pop back up on them
    setWindowVisible(false);
    setVisible(false);
  };

  const renderGeneral = () => {
    let authCookie = LocalStorageUtil.AuthCookie;
    let authState = LocalStorageUtil.AuthState;

    let sessionTime = authContext.loginTimeStamp;

    const items: DescriptionType[] = [
      { label: 'Logged In?', valueFunc: () => ObjectUtil.ToBoolean(authContext.isLoggedIn) ? 'Yes' : 'No' },
      { label: 'Bearer Token', valueFunc: () => authCookie || '[blank]' },
      { label: 'Auth State', valueFunc: () => authState || '[blank]' },
      {
        label: 'Session Start Time',
        reloadInterval: 'second',
        valueFunc: () => {
          if (sessionTime == null) {
            return '[blank]';
          }

          const sessionDuration = moment.duration(moment().diff(sessionTime));

          const sessionString = sessionTime.isSameOrAfter(moment(), 'day')
            ? `${sessionTime.format('LT')}` // Session started today
            : `Yesterday at ${sessionTime.format('LT')}`; // Session started yesterday for those of us working over midnight
          return `${sessionString} (${Math.floor(sessionDuration.asMinutes())}:${Math.floor(sessionDuration.asSeconds()) % 60})`;
        }
      },
      {
        label: 'Session End Time',
        reloadInterval: '5 second',
        valueFunc: () => {
          let authExpiration = LocalStorageUtil.AuthExpiration;
          if (authExpiration == null) {
            return '[blank]';
          }

          const authExpirationMoment = moment(authExpiration);
          const authExpirationDuration = moment.duration(authExpirationMoment.diff(moment()));
          const expirationTime = authExpirationMoment.isAfter(moment(), 'day')
            ? `Tomorrow at ${authExpirationMoment.format('LT')}` // Session ends tomorrow
            : `${authExpirationMoment.format('LT')}`;
          return `${expirationTime} (${Math.floor(authExpirationDuration.asMinutes())} minutes remaining)`;
        }
      },
    ];

    return <DebugOverlayItem items={items} />;
  };

  // const renderProfile = () => {
  //   if (authContext.profile == null) {
  //     return '[blank]';
  //   }

  //   const {
  //     // Important ones at the top
  //     displayName,
  //     emailAddress,
  //     tfaEnabled,
  //     tfaPreference,

  //     // Less important ones
  //     firstName,
  //     lastName,
  //     emailAddressValidated,
  //     phoneNumber,
  //     phoneNumberValidated,
  //     notificationsEnabled,
  //     accountCount,
  //     locationCount
  //   } = authContext.profile;

  //   const items: DescriptionType[] = [
  //     { label: 'Display Name', valueFunc: () => displayName || '[blank]', showWhenReduced: true },
  //     { label: 'Email', valueFunc: () => emailAddress || '[blank]', showWhenReduced: true },
  //     { label: 'Tfa Enabled', valueFunc: () => ObjectUtil.ToBoolean(tfaEnabled) ? 'Yes' : 'No', showWhenReduced: true },
  //     { label: 'Tfa Preference', valueFunc: () => tfaPreference || '[blank]', showWhenReduced: true },

  //     { label: 'First Name', valueFunc: () => firstName || '[blank]' },
  //     { label: 'Last Name', valueFunc: () => lastName || '[blank]' },
  //     { label: 'Email Validated', valueFunc: () => ObjectUtil.ToBoolean(emailAddressValidated) ? 'Yes' : 'No' },
  //     { label: 'Phone Number', valueFunc: () => phoneNumber || '[blank]' },
  //     { label: 'Phone Validated', valueFunc: () => ObjectUtil.ToBoolean(phoneNumberValidated) ? 'Yes' : 'No' },
  //     { label: 'Notifications?', valueFunc: () => ObjectUtil.ToBoolean(notificationsEnabled) ? 'Yes' : 'No' },
  //     { label: 'Accounts', valueFunc: () => accountCount || '[blank]' },
  //     { label: 'Locations', valueFunc: () => locationCount || '[blank]' },
  //   ];

  //   return <DebugOverlayItem items={items} startReduced />;
  // };

  const renderBuild = () => {
    // For this we need to look into the process.env
    const {
      // Important ones at the top
      REACT_APP_BUILD_DATE,
      NODE_ENV,
      REACT_APP_USE_PROXY,

      // Less important ones
      REACT_APP_API_ENVIRONMENT,
      REACT_APP_API_URL,
      REACT_APP_BEARER_URL,
    } = process.env;

    const items: DescriptionType[] = [
      { label: 'Build Date', valueFunc: () => (REACT_APP_BUILD_DATE != null ? moment(REACT_APP_BUILD_DATE).utc().format('LLL z Z') : 'Build date not detected!'), showWhenReduced: true },
      { label: 'Node Environment', valueFunc: () => NODE_ENV || '[blank]', showWhenReduced: true },
      { label: 'Use Proxy', valueFunc: () => ObjectUtil.ToBoolean(REACT_APP_USE_PROXY) ? 'Yes' : 'No', showWhenReduced: true },

      { label: 'API Environment', valueFunc: () => REACT_APP_API_ENVIRONMENT || '[blank]' },
      { label: 'API Url', valueFunc: () => REACT_APP_API_URL || '[blank]' },
      { label: 'Bearer Url', valueFunc: () => REACT_APP_BEARER_URL || '[blank]' },
    ];

    return <DebugOverlayItem items={items} />;
  };

  const renderBrowserLocation = () => {
    const {
      // Important ones at the top
      pathname,
      search,
      state,

      // Less important ones
      key,
      hash,
    } = location;

    const items: DescriptionType[] = [
      { label: 'Pathname', valueFunc: () => pathname || '', showWhenReduced: true },
      { label: 'Search', valueFunc: () => search || '', showWhenReduced: true },
      {
        label: 'State',
        showWhenReduced: true,
        valueFunc: () => {
          if (state == null) {
            return '';
          }
          if (typeof state === 'string') {
            return state;
          }
          if (typeof state === 'object') {
            return <pre>{JSON.stringify(state, null, 2)}</pre>;
          }
        }
      },

      { label: 'Key', valueFunc: () => key || '' },
      { label: 'Hash', valueFunc: () => hash || '' },
    ];

    return <DebugOverlayItem items={items} startReduced />;
  };

  // Handles rendering all of the above and does so because we are now allowing a second window to be present
  const renderAll = () => {
    return <>
      <h2>Debug Info</h2>
      <Descriptions bordered column={1} size='small' layout='horizontal'>
        <Descriptions.Item label="General">{renderGeneral()}</Descriptions.Item>
        {/* <Descriptions.Item label="Profile">{renderProfile()}</Descriptions.Item> */}
        <Descriptions.Item label="Build Info">{renderBuild()}</Descriptions.Item>
        <Descriptions.Item label="Browser Location">{renderBrowserLocation()}</Descriptions.Item>
      </Descriptions>
    </>;
  };

  // So, for now, we will want to show a small box on the bottom right that will popup with the rest when clicked
  if (!visible) {
    return <Button className='show-debug-panel' onClick={() => setVisible(prev => !prev)}>Dev</Button>;
  }

  if (windowVisible) {
    return <DebugPortal className='debug-overlay' onClose={handleWindowClose}>
      {renderAll()}
    </DebugPortal>;
  }

  return <div className='debug-overlay modal-edition'>
    <Space className='debug-panel-buttons'>
      <Button onClick={() => setWindowVisible(true)}>As Window</Button>
      <Button type='primary' onClick={() => setVisible(prev => !prev)}>Hide</Button>
    </Space>
    {renderAll()}
  </div>;
};

export default DebugOverlay;
