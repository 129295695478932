import { Layout } from 'antd';
import React from 'react';
import './CenteredLayout.less';

const { Content } = Layout;

const CenteredLayout: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <Layout className='centered-layout'>
      <Content className='content-container'>
        <div className='inner-container'>
          {props.children}
          <div className='lower-logo-container'>
            {/* <img className='lower-logo' src={lowerLogo} alt='BackOffice Logo with Text' /> */}
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default CenteredLayout;
