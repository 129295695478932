import * as React from 'react';
import moment from 'moment';
import { EditFilled, EditOutlined } from '@ant-design/icons';
import { Button, Typography as AntTypography } from 'antd';
import StringUtil from './StringUtil';
import { EllipsisConfig } from 'antd/lib/typography/Base';
import { TextProps } from 'antd/lib/typography/Text';
import ObjectUtil from './ObjectUtil';

export type BooleanRenderType = 'YesNo' | 'TrueFalse';

export interface MyTypographyProps {
  title?: string;
  disabled?: boolean;
  code?: boolean;
  mark?: boolean;
  underline?: boolean;
  delete?: boolean;
  strong?: boolean;
  keyboard?: boolean;
  italic?: boolean;

  editable?: boolean; // | EditConfig;
  copyable?: boolean; // | CopyConfig;
  ellipsis?: boolean; // | EllipsisConfig;
}

class TableRenderers {

  /*** Dates ***/

  /**
   * Renders a date in short format. Example: 6/2/2022
   * @param property The property on the table
   * @param hideOldOrEmptyDates Hides dates that are null, empty or are before Jan 1st, 1900, as they are considered 'null' at that point
   */
  public ShortDate<T>(property: keyof T, renderOldOrEmptyDates: boolean = true) {
    return (value: string, record: T) => {
      let date = moment.utc(record[property] as any).local();
      // Sept 8th, 2022: Removing utc/local support since we just show the local time
      // date = utcOrLocal === 'local' ? date.local() : date.utc();

      if (!date.isValid()) {
        if (process.env.NODE_ENV === 'development') {
          console.log('[ShortDate] Invalid date', { record, property, value: record[property] });
        }
        return '[Invalid Date]';
      }

      if (!renderOldOrEmptyDates) {
        // Check if date is null, empty or 'old'
        if (StringUtil.IsNullOrEmpty(record[property] as any) || date.isBefore(moment('1900-01-01'))) {
          return null;
        }
      }

      return date.format('L');
    };
  }

  /** Renders a date in long format. Example: June 2, 2022 */
  public LongDate<T>(property: keyof T, utcOrLocal: 'utc' | 'local' = 'local') {
    return (value: string, record: T) => {
      // We can assume that the API will give us a proper timezone, or we can ensure it
      let date = moment.utc(record[property] as any);
      date = utcOrLocal === 'local' ? date.local() : date.utc();

      if (!date.isValid()) {
        if (process.env.NODE_ENV === 'development') {
          console.log('[ShortDate] Invalid date', { record, property, value: record[property] });
        }
        return '[Invalid Date]';
      }

      return date.format('LL');
    };
  }

  /** Renders the time part of a datetime. Example: 12:30 PM */
  public Time<T>(property: keyof T, utcOrLocal: 'utc' | 'local' = 'local') {
    return (value: string, record: T) => {
      // We can assume that the API will give us a proper timezone, or we can ensure it
      let date = moment.utc(record[property] as any);
      date = utcOrLocal === 'local' ? date.local() : date.utc();

      if (!date.isValid()) {
        if (process.env.NODE_ENV === 'development') {
          console.log('[ShortDate] Invalid date', { record, property, value: record[property] });
        }
        return '[Invalid Date]';
      }

      return date.format('LT');
    };
  }

  /** Renders a datetime in long format. Example: Jun 2, 2022 12:30 PM */
  public LongDateTime<T>(property: keyof T, utcOrLocal: 'utc' | 'local' = 'local') {
    return (value: string, record: T) => {
      // We can assume that the API will give us a proper timezone, or we can ensure it
      let date = moment.utc(record[property] as any);
      date = utcOrLocal === 'local' ? date.local() : date.utc();

      if (!date.isValid()) {
        if (process.env.NODE_ENV === 'development') {
          console.log('[ShortDate] Invalid date', { record, property, value: record[property] });
        }
        return '[Invalid Date]';
      }

      return date.format('lll');
    };
  }

  /** Renders a datetime in long format with the timezone. Example: Jun 2, 2022 12:30 PM UTC */
  public LongDateTimeWithTimeZone<T>(property: keyof T, utcOrLocal: 'utc' | 'local' = 'local') {
    return (value: string, record: T) => {
      // We can assume that the API will give us a proper timezone, or we can ensure it
      let date = moment.utc(record[property] as any);
      date = utcOrLocal === 'local' ? date.local() : date.utc();

      if (!date.isValid()) {
        if (process.env.NODE_ENV === 'development') {
          console.log('[ShortDate] Invalid date', { record, property, value: record[property] });
        }
        return '[Invalid Date]';
      }

      return date.format('lll z'); // Timezones are hard, so we banking on .utc and .local working as intended. Other timezones will need the moment-timezones package
    };
  }

  /*** Number and Currency ***/

  /**
   * Renders a number as the local currency. Example: 1234.23 -> $1,234.23
   * @param property The property on the table
   * @param removeCurrencySymbol Removes the currency symbol, ie `$123.45` -> `123.45`
   * @param renderBlankIfZero Renders an empty string if it would instead render a `0.00`. Takes precedence over renderSimpleZero
   * @param renderSimpleZero Renders a `0` when it would instead render `0.00`
   */
  public Currency<T>(property: keyof T, removeCurrencySymbol: boolean = false, renderBlankIfZero: boolean = false, renderSimpleZero: boolean = false) {
    return (value: string, record: T) => {
      // Swiped from here: https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
      // However, it adds the dollar sign and the project does not like dollar signs
      const currency = Number(record[property]);
      if (Number.isNaN(currency)) {
        return '';
      }

      if (currency === 0) {
        if (renderBlankIfZero) {
          return '';
        }
        if (renderSimpleZero) {
          return '0';
        }
      }

      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        currencySign: 'accounting',
        currencyDisplay: 'symbol'
      })
        .format(currency);

      // Remove the currency symbol as needed. This is the best we can do without writing our own
      return removeCurrencySymbol ? formatted.replaceAll('$', '') : formatted;
    };
  }

  /*** Text ***/
  // Idk what I was doing here
  public General<T>(property: keyof T) {
    return (value: string, record: T) => {
      let text = record[property] as any;
      return <span>{text}</span>;
    };
  }

  /** Renders text with ellipses if the text is too long. Only supports single line and the column ***should*** have a width defined. Further testing has indicated that it will just push the other columns around, depending on the browser */
  public Ellipses<T>(property: keyof T, valueOverride?: (record: T) => React.ReactNode) {
    return this.Typography(property, { ellipsis: true }, valueOverride);
  }

  /** Exposes a more generic typography component, where the user is free more options */
  public Typography<T>(property: keyof T, properties: MyTypographyProps, valueOverride?: (record: T) => React.ReactNode) {
    return (value: string, record: T) => {
      let text = valueOverride != null ? valueOverride(record) : record[property] as any;

      // Idk why you would do this, but here we are
      if (properties == null) {
        return <AntTypography.Text>{text}</AntTypography.Text>;
      }

      // Convert to TextProps, so we can set the other props on it
      const props = {...properties} as TextProps;
      if (properties.ellipsis)
        props.ellipsis = { tooltip: text };
      if (properties.copyable)
        props.copyable = { text: text };
      if (properties.editable)
        props.editable = { tooltip: text };

      // We need to render things a tad different when ellipse is selected
      if (props.ellipsis != null) {
        return <div className='special-text' style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          <AntTypography.Text {...props}>{text}</AntTypography.Text>
        </div>;
      }
      return <AntTypography.Text {...props}>{text}</AntTypography.Text>;
    };
  }

  public Boolean<T>(property: keyof T, booleanType: BooleanRenderType = 'TrueFalse', hideFalseValues: boolean = false) {
    return (value: string, record: T) => {
      let bool = ObjectUtil.ToBoolean(record[property]);

      if (hideFalseValues && !bool) {
        return null;
      }

      switch (booleanType) {
        case 'YesNo':
          return bool ? 'Yes' : 'No';
        case 'TrueFalse':
        default:
          return bool ? 'True' : 'False';
      }
    };
  }

  // Eh, this is just handy
  public ButtonWithText<T>(text?: (record: T) => React.ReactNode | React.ReactNode, action?: (record: T) => void, icon: React.ReactNode = <EditFilled />): (value: string, record: T) => React.ReactNode {
    return (value: string, record: T) => {
      const contents = text == null
        ? null
        : React.isValidElement(text)
          ? text
          : text(record);
      return <Button
        style={{ borderRadius: 200 }}
        type='default'
        icon={icon}
        onClick={() => (action && action(record))}
      >
        {contents}
      </Button>;
    };
  }
}

class TableSorters {
  /** A more generic and therefor slower sorting method */
  public CommonSorter<T>(property: keyof T) {
    return (left: T, right: T) => {
      const value = left != null ? left[property] : undefined;

      switch (typeof value) {
        case 'string':
          return this.StringSorter(property)(left, right);
        case 'number':
          return this.NumberSorter(property)(left, right);
        case 'boolean':
          return this.BooleanSorter(property)(left, right);
        case 'object':
          if (moment.isMoment(value)) {
            return this.DateSorter(property)(left, right);
          }
          break;
        default:
          break;
      }
      return this.StringSorter(property)(left, right);
    };
  }

  public StringSorter<T>(property: keyof T) {
    return (left: T, right: T) => {
      const a = left != null && left[property] != null ? String(left[property]) : '';
      const b = right != null && right[property] != null ? String(right[property]) : '';
      return a.localeCompare(b);
    };
  }

  public NumberSorter<T>(property: keyof T) {
    return (left: T, right: T) => {
      const a = left != null && left[property] != null ? Number(left[property]) : 0;
      const b = right != null && right[property] != null ? Number(right[property]) : 0;
      return a - b;
    };
  }

  public BooleanSorter<T>(property: keyof T) {
    return (left: T, right: T) => {
      const a = left != null && left[property] != null ? Boolean(left[property]) : false;
      const b = right != null && right[property] != null ? Boolean(right[property]) : false;
      // Pulled from here since boolean is odd: https://stackoverflow.com/questions/17387435/javascript-sort-array-of-objects-by-a-boolean-property
      return a === b ? 0 : a ? -1 : 1;
    };
  }

  public DateSorter<T>(property: keyof T) {
    // We only support Moment because Date be so last year. #puns 😂
    // I think that Date(0) is equivalent to the 1970s
    return (left: T, right: T) => {
      const a = left != null ? moment.utc(left[property] as any) : moment.utc(new Date(0));
      const b = right != null ? moment.utc(right[property] as any) : moment.utc(new Date(0));
      return a.diff(b);
    };
  }
}

class TableFunctions {
  /** Fixes sorting when there are multiple of the same sort value. Array is assumed to be sorted already */
  public FixSortOrderNumber<T>(array: T[], property: keyof T) {
    // It doesn't need to be fancy, it just needs to work
    // Sanity check
    if (!Array.isArray(array) || array.length < 2) {
      return array;
    }

    // Honestly... The key won't really mean anything and adding to the sort will just make it angry. However, replacing it with a number solves so many problems
    // Sometimes the easiest solution...
    return [...array].map((item, index) => {
      return {
        ...item,
        [property]: index // Change the property with the index
      };
    });
  }

  /** Fixes sorting when there are multiple of the same sort value. Array is assumed to be sorted already */
  public FixSortOrderString<T>(array: T[], property: keyof T) {
    // It doesn't need to be fancy, it just needs to work
    // Sanity check
    if (!Array.isArray(array) || array.length < 2) {
      return array;
    }

    // Honestly... The key won't really mean anything and adding to the sort will just make it angry. However, replacing it with a number solves so many problems
    // Sometimes the easiest solution...
    return [...array].map((item, index) => {
      return {
        ...item,
        [property]: String(index) // Change the property with the index
      };
    });
  }
}

// TODO: Cleanup the old sorters and relink them to TableSorters class
class TableExpressions {
  // This all needs to be removed and consolidated into the Sorters var
  private tempTableSorter = new TableSorters();
  public CommonSorter = this.tempTableSorter.CommonSorter;
  public StringSorter = this.tempTableSorter.StringSorter;
  public NumberSorter = this.tempTableSorter.NumberSorter;
  public BooleanSorter = this.tempTableSorter.BooleanSorter;
  public DateSorter = this.tempTableSorter.DateSorter;
  // End consolidate

  public Sorters = new TableSorters();

  public Renderers = new TableRenderers();

  public Methods = new TableFunctions();
}

export default new TableExpressions();
